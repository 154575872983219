import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-area',
  templateUrl: './footer-area.component.html',
  styleUrls: ['./footer-area.component.scss']
})
export class FooterAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
