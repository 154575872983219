<header class="header-area">
  <div class="header-top bg-img" style="background-image:url(assets/img/icon-img/header-shape.png);">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-7 col-12 col-sm-8">
          <div class="header-contact">
            <ul>
              <li><i class="fa fa-phone"></i> +91 720 923 8110</li>
              <li><i class="fa fa-envelope-o"></i><a href="#">paraminfosolution@gmail.com</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-5 col-12 col-sm-4">
          <div class="login-register">
            <!--<ul>
                <li><a href="login-register.html">Login</a></li>
                <li><a href="login-register.html">Register</a></li>
            </ul>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="header-bottom sticky-bar clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-6 col-4">
          <div class="logo">
            <a href="index.html">
              <img alt="" class="image-logo-size" src="assets/img/logo/pis-logo.png">
            </a>
          </div>
        </div>
        <div class="col-lg-10 col-md-6 col-8">
          <div class="menu-cart-wrap">
            <div class="main-menu">
              <nav>
                <ul>
                  <li><a href="index.html"> HOME </a></li>
                  <li><a href="about-us.html"> ABOUT  </a></li>
                  <li><a routerLink="/course"> COURSES / PROGRAMMES</a></li>
                  <li><a href="contact.html"> CONTACT </a></li>
                </ul>
              </nav>
            </div>

          </div>
        </div>
      </div>
      <div class="mobile-menu-area">
        <div class="mobile-menu">
          <nav id="mobile-menu-active">
            <ul class="menu-overflow">
              <li><a href="index.html">HOME</a></li>
              <li><a href="about-us.html">About us</a></li>
              <li><a href="course.html">course page</a></li>
              <li><a routerLink="/course">course details</a></li>
              <li><a href="contact.html">Contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>
