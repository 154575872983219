<app-top-nav-header></app-top-nav-header>



<!--<div class="teacher-area pt-130 pb-100">
  <div class="container">
    <div class="section-title mb-75">
      <h2>Best <span>Teacher</span></h2>
      <p> </p>
    </div>
    <div class="custom-row">
      <div class="custom-col-5">
        <div class="single-teacher mb-30">
          <div class="teacher-img">
            <img src="assets/img/teacher/teacher-1.jpg" alt="">
          </div>
          <div class="teacher-content-visible">
            <h4>Robi Khan</h4>
            <h5>Lecturer</h5>
          </div>
          <div class="teacher-content-wrap">
            <div class="teacher-content">
              <h4>Fawd Khan</h4>
              <h5>Lecturer</h5>
              <p>Tempor incididunt magna aliqua.</p>
              <div class="teacher-social">
                <ul>
                  <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="youtube-play" href="#"><i class="fa fa-youtube-play"></i></a></li>
                  <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-col-5">
        <div class="single-teacher mb-30">
          <div class="teacher-img">
            <img src="assets/img/teacher/teacher-2.jpg" alt="">
          </div>
          <div class="teacher-content-visible">
            <h4>Jui Khan</h4>
            <h5>Lecturer</h5>
          </div>
          <div class="teacher-content-wrap">
            <div class="teacher-content">
              <h4>Fawd Khan</h4>
              <h5>Lecturer</h5>
              <p>Tempor incididunt magna aliqua.</p>
              <div class="teacher-social">
                <ul>
                  <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="youtube-play" href="#"><i class="fa fa-youtube-play"></i></a></li>
                  <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-col-5">
        <div class="single-teacher mb-30">
          <div class="teacher-img">
            <img src="assets/img/teacher/teacher-3.jpg" alt="">
          </div>
          <div class="teacher-content-visible">
            <h4>Fawd Khan</h4>
            <h5>Lecturer</h5>
          </div>
          <div class="teacher-content-wrap">
            <div class="teacher-content">
              <h4>Fawd Khan</h4>
              <h5>Lecturer</h5>
              <p>Tempor incididunt magna aliqua.</p>
              <div class="teacher-social">
                <ul>
                  <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="youtube-play" href="#"><i class="fa fa-youtube-play"></i></a></li>
                  <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-col-5">
        <div class="single-teacher mb-30">
          <div class="teacher-img">
            <img src="assets/img/teacher/teacher-4.jpg" alt="">
          </div>
          <div class="teacher-content-visible">
            <h4>Fawd Khan</h4>
            <h5>Lecturer</h5>
          </div>
          <div class="teacher-content-wrap">
            <div class="teacher-content">
              <h4>Fawd Khan</h4>
              <h5>Lecturer</h5>
              <p>Tempor incididunt magna aliqua.</p>
              <div class="teacher-social">
                <ul>
                  <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="youtube-play" href="#"><i class="fa fa-youtube-play"></i></a></li>
                  <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-col-5">
        <div class="single-teacher mb-30">
          <div class="teacher-img">
            <img src="assets/img/teacher/teacher-5.jpg" alt="">
          </div>
          <div class="teacher-content-visible">
            <h4>Jui Khan</h4>
            <h5>Lecturer</h5>
          </div>
          <div class="teacher-content-wrap">
            <div class="teacher-content">
              <h4>Fawd Khan</h4>
              <h5>Lecturer</h5>
              <p>Tempor incididunt magna aliqua.</p>
              <div class="teacher-social">
                <ul>
                  <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="youtube-play" href="#"><i class="fa fa-youtube-play"></i></a></li>
                  <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<router-outlet></router-outlet>

<app-footer-area></app-footer-area>
