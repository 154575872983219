import { Component } from '@angular/core';
import { Meta} from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'paramInfoSolutionsUI';
  constructor(private metaTagService:Meta) {

  }

  ngOnInit() {
    this.metaTagService.addTags([
      {
        name: 'keywords', content: 'computer Courses, Tally, HTML, C#, C++, Advance Diploma In Computer Application, Diploma In Computer Application(DCA), Computer Teachers Training Course(CTTC), Diploma in Programming & Web Designing, Transactions Allowed in a Linear Line Yard, Desktop Publishing,	Word,	Photoshop, Corel Draw '
      }
      , { name: 'author', content: 'Param Info Solutions' }
      , { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      , { name: 'date', content: '26/09/2022', scheme: 'MM/dd/yyyy' }
      , {charset:'UTF-8'}
    ]);
  }
 

}
