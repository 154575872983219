import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { TopNavHeaderComponent } from './shared-template/top-nav-header/top-nav-header.component';
import { FooterAreaComponent } from './shared-template/footer-area/footer-area.component';

@NgModule({
  declarations: [
    AppComponent,
    TopNavHeaderComponent,
    FooterAreaComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
