<footer class="footer-area">
  <div class="footer-top bg-img default-overlay pt-130 pb-80" style="background-image:url(assets/img/bg/bg-4.jpg);">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget mb-40">
            <div class="footer-title">
              <h4>ABOUT US</h4>
            </div>
            <div class="footer-about">
              <p>
                We provide the students Computer courses in Saharsa which prepare students for growing their career in the industry of computer.
              </p>
              <div class="f-contact-info">
                <div class="single-f-contact-info">
                  <i class="fa fa-home"></i>
                  <span>Gangla Chowk, Saharsa, Bihar</span>
                </div>
                <div class="single-f-contact-info">
                  <i class="fa fa-envelope-o"></i>
                  <span><a href="#">paraminfosolution@gmail.com</a></span>
                </div>
                <div class="single-f-contact-info">
                  <i class="fa fa-phone"></i>
                  <span> +91 720 923 8110</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="footer-widget mb-40">
            <div class="footer-title">
              <h4>QUICK LINK</h4>
            </div>
            <div class="footer-list">
              <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="#">About Us</a></li>
                <li><a href="#">Courses</a></li>
                <li><a href="#">Admission</a></li>
                <li><a href="#">Terms & Conditions</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="footer-widget negative-mrg-30 mb-40">
            <div class="footer-title">
              <h4>COURSES</h4>
            </div>
            <div class="footer-list">
              <ul>
                <li><a href="#">ADCA </a></li>
                <li><a href="#">DCA</a></li>
                <li><a href="#">CTTC</a></li>
                <li><a href="#">DPWD</a></li>
                <li><a href="#">TALLY</a></li>
                <li><a href="#">DTP</a></li>
                <li><a href="#">DOM</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget mb-40">
            <div class="footer-title">
              <h4>GALLERY</h4>
            </div>
            <div class="footer-gallery">
              <ul>
                <li><a href="#"><img src="assets/img/gallery/gallery-1.png" alt=""></a></li>
                <li><a href="#"><img src="assets/img/gallery/gallery-2.png" alt=""></a></li>
                <li><a href="#"><img src="assets/img/gallery/gallery-3.png" alt=""></a></li>
                <li><a href="#"><img src="assets/img/gallery/gallery-4.png" alt=""></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom pt-15 pb-15">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-12">
          <div class="copyright">
            <p>
              Copyright ©
              <a href="#">Param Info Solutions</a>
              . All Right Reserved.
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="footer-menu-social">
            <div class="footer-menu">
              <ul>
                <li><a href="#">Privecy & Policy</a></li>
                <li><a href="#">Terms & Conditions of Use</a></li>
              </ul>
            </div>
            <div class="footer-social">
              <ul>
                <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a class="youtube" href="#"><i class="fa fa-youtube-play"></i></a></li>
                <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
